<template>
	<section class="container mt-4">
		<div class="d-flex justify-content-end mb-3" v-if="$can('create', 'Company')">
			<router-link to="/company/add">
				<b-button variant="primary"> <i class="fas fa-plus-circle me-2"></i> {{ $t('companyList.create') }} </b-button>
			</router-link>
		</div>

		<b-card v-if="isAdvisorOrAdmin" class="mb-3">
			<b-card-header>
				<b-form-group>
					<b-form-checkbox v-model="filterAdvisor" switch> Rådgiver filtrering </b-form-checkbox>
				</b-form-group>
			</b-card-header>
			<b-card-body v-if="filterAdvisor">
				<div class="row">
					<div class="col-md-4 mb-3">
						<InputDropdown v-model="selectedUser" :options="advisorsAndAdmins" placeholder="Rådgiver" />
					</div>
					<div class="col-md-4 mb-3">
						<b-form-checkbox v-model="primaryAdvisor" :disabled="!filterAdvisor" class="me-3"> Primære rådgiver </b-form-checkbox>
						<b-form-checkbox v-model="secondaryAdvisor" :disabled="!filterAdvisor"> Sekundær rådgiver </b-form-checkbox>
					</div>
					<div class="col-md-4 mb-3">
						<b-form-checkbox v-model="showInactiveCompanies" switch> Vis kun opsagte virksomheder </b-form-checkbox>
					</div>
				</div>
			</b-card-body>
		</b-card>

		<b-form-input v-model="globalfilter" placeholder="Søg" class="mb-3" />

		<BaseTable
			:items="companies"
			@currentPageChanged="currentPageChanged"
			@perPageChanged="perPageChanged"
			:func="myCompare"
			:fields="fields"
			:isBusy="isBusy"
		>
			<template v-slot:cell(index)="data">
				{{ currentPage * perPage - perPage + data.index + 1 }}
			</template>
			<template v-slot:cell(companyInfo.name)="data">
				<router-link :to="{ name: 'Company', params: { companyId: data.item.id } }">
					{{ data.item.companyInfo.name }}
				</router-link>
			</template>
			<template v-slot:cell(employees)="row">
				<b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
					<i class="fas fa-users me-2"></i> {{ row.item.employees.length }} Medarbejder
				</b-button>
			</template>
			<template #row-details="{ item }">
				<div class="col-12 mt-2" v-for="(employeeId, index) in item.employees" :key="`employee-${employeeId}`">
					<div v-if="usersIsReady">{{ index + 1 }}. {{ getUser(employeeId) }}</div>
					<div v-else>{{ index + 1 }}. henter data... <b-spinner small type="grow"></b-spinner></div>
				</div>
			</template>
		</BaseTable>
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import Swal from 'sweetalert2'
import clientVersionMixin from '@/mixins/clientVersionMixin'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'

export default {
	name: 'CompanyList',
	components: {
		BaseTable,
		InputDropdown,
		InputCheck,
	},
	mixins: [clientVersionMixin],
	created() {
		this.selectedUser = this.$store.getters['auth/uid']
	},
	watch: {
		filterAdvisor: {
			handler: 'setCookie',
			immediate: false,
		},
	},
	data() {
		return {
			currentPage: 1,
			perPage: 30,
			globalfilter: '',
			filterAdvisor: true,
			selectedUser: null,
			primaryAdvisor: true,
			secondaryAdvisor: false,
			showInactiveCompanies: false,
			fields: [
				{
					key: 'index',
					label: '',
				},
				{
					key: 'companyInfo.name',
					label: i18n.t('companyList.table.name'),
					sortable: true,
				},
				{
					key: 'companyInfo.cvr',
					label: i18n.t('companyList.table.cvr'),
					sortable: true,
				},
				{
					key: 'employees',
					label: i18n.t('companyList.table.employees'),
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$emit('updateLoader')
		await this.$store.dispatch('company/getCompanies') // Load data fra api+
		this.checkClientVersion()
		this.loadCookie()
	},
	methods: {
		loadCookie() {
			this.filterAdvisor = this.$cookies.get('showCompanyList') == 'true'
		},
		currentPageChanged(page) {
			this.currentPage = page
		},
		perPageChanged(perPage) {
			this.perPage = perPage
		},
		onDelete(id) {
			Swal.fire({
				title: 'Slet virksomhed?',
				text: 'Vil du slette denne virksomhed? Du kan ikke fortryde dette.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja overskriv!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						await this.$store.dispatch('companyVuex/deleteCompany', id)
					} catch (error) {
						Swal.showValidationMessage(`Request failed: ${error}`)
					}
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					Swal.fire('Slettet!', 'Virksomheden er nu slettet', 'success')
				}
			})
		},
		getUser(employeeId) {
			var user = this.users.find((user) => user.id === employeeId)
			return user?.fullName
		},
		setCookie() {
			console.log('setting cookie')
			this.$cookies.set('showCompanyList', this.filterAdvisor)
		},
		myCompare(a, b, key) {
			if (key === 'employees') {
				return a[key].length - b[key].length
			}
			return a[key] > b[key] ? 1 : -1
		},
	},
	computed: {
		companies() {
			var companies = this.$store.getters['company/companies']
			companies = companies.sort((a, b) => (a.companyInfo.name > b.companyInfo.name ? 1 : -1))
			return companies
				.filter((company) => {
					return company.isActive == !this.showInactiveCompanies
				})
				.filter((obj) => {
					if (this.globalfilter == '') {
						return true
					} else {
						return Object.keys(obj).some((key) => {
							try {
								var filtered = JSON.stringify(obj[key]).toLowerCase()
								return filtered.includes(this.globalfilter.toLowerCase())
							} catch (err) {
								console.log(key)
							}
						})
					}
				})
				.filter((obj) => {
					if (this.isAdvisorOrAdmin) {
						if (!this.filterAdvisor) {
							return true
						}
						if (this.selectedUser == null) {
							return true
						} else {
							return (
								(this.primaryAdvisor && obj.companyAdvisors.primaryAdvisor == this.selectedUser) ||
								(this.secondaryAdvisor && obj.companyAdvisors.secondaryAdvisor == this.selectedUser)
							)
						}
					} else {
						return true
					}
				})
				.map((company) => {
					var isResignedCompany = company.companyInfo.endDate != null
					let rowVariant = isResignedCompany ? 'danger' : ''
					return {
						...company,
						_rowVariant: rowVariant,
					}
				})
		},
		users() {
			return this.$store.getters['user/users']
		},
		advisorsAndAdmins() {
			return this.users
				.filter((user) => user.role.isAdmin || user.role.isAdvisor)
				.map((user) => {
					return {
						value: user?.id,
						text: user?.fullName,
					}
				})
				.sort((a, b) => (a.text > b.text ? 1 : -1))
		},
		isBusy() {
			return !this.$store.getters['company/isReady']
		},
		isAdvisorOrAdmin() {
			return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
		},
		usersIsReady() {
			return this.$store.getters['user/isReady']
		},
	},
}
</script>

<style scoped>
.container {
	max-width: 1200px;
	padding: 20px;
}

/* Streamlined Styling */
.btn-primary {
	/* Style the primary button directly */
	background-color: #007bff; /* Example: Use Bootstrap's primary color */
	border-color: #007bff;
}

.btn-outline-primary {
	color: #007bff;
	border-color: #007bff;
}

.btn-outline-primary:hover {
	background-color: #007bff;
	border-color: #007bff;
	color: white;
}

.form-control {
	/* Style form inputs */
	border-radius: 5px;
}

.card-header {
	background-color: #f8f9fa; /* Light gray background for card headers */
	border-bottom: 1px solid #dee2e6;
}

.table th,
.table td {
	vertical-align: middle; /* Align table cell content vertically */
}

/* Responsive adjustments (if needed) */
@media (max-width: 768px) {
	/* Adjustments for smaller screens */
}
</style>
