<template>
	<section class="container mt-4">
		<div class="row">
			<div class="col-12 mb-3">
				<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />
			</div>
			<div class="col-12">
				<BaseTable
					:items="companies"
					@currentPageChanged="currentPageChanged"
					:func="myCompare"
					@perPageChanged="perPageChanged"
					:fields="fields"
					:isBusy="isBusy"
				>
					<template v-slot:cell(index)="data">
						{{ currentPage * perPage - perPage + data.index + 1 }}
					</template>
					<template v-slot:cell(companyInfo.name)="data">
						<router-link :to="{ name: 'Company', params: { companyId: data.item.id } }">
							{{ data.item.companyInfo.name }}
						</router-link>
					</template>
					<template v-slot:cell(employees)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="primary">
							<i class="fas fa-users"></i>
							Vis fratrådte medarbejdere ({{ row.item.employeePolicies.length }})
						</b-button>
					</template>
					<template #row-details="{ item }">
						<b-table fixed striped hover :items="item.employeePolicies" :func="myCompare" :fields="fieldsEmployeePolicies">
							<template v-slot:cell(employeeId)="data">
								<router-link
									:to="{
										name: 'CompanyEmployeeInfo',
										params: { companyId: data.item.companyId, employeeId: data.item.employeeId, policyId: data.item.id },
									}"
								>
									{{ data.index + 1 }}. {{ data.item.userName }}
								</router-link>
							</template>
							<template v-slot:cell(employeeCondition.resignationDate)="data">
								<h5>
									<b-badge variant="warning">{{ getFormattedDate(data.item.employeeCondition.resignationDate) }}</b-badge>
								</h5>
							</template>
							<template v-slot:cell(employeeCondition.employmentDate)="data">
								<h5>
									<b-badge variant="info">{{ getFormattedDate(data.item.employeeCondition.employmentDate) }}</b-badge>
								</h5>
							</template>
							<template v-slot:cell(isActive)="data">
								<h5>
									<b-badge variant="success" v-if="data.item.isActive"><font-awesome-icon icon="check" /></b-badge>
									<b-badge variant="danger" v-else> <font-awesome-icon icon="times" /> </b-badge>
								</h5>
							</template>
						</b-table>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>
<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import Swal from 'sweetalert2'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'

export default {
	name: 'ResignedCompanyList',
	components: {
		BaseTable,
		InputDropdown,
		InputCheck,
	},

	data() {
		return {
			currentPage: 1,
			perPage: 30,
			globalfilter: '',
			filterAdvisor: true,
			selectedUser: null,
			primaryAdvisor: true,
			secondaryAdvisor: false,
			fieldsEmployeePolicies: [
				{
					key: 'employeeId',
					label: i18n.t('companyList.table.employee'),
				},
				{
					key: 'cpr',
					label: 'CPR',
				},

				{
					key: 'employeeCondition.resignationDate',
					label: 'Fratrædelsesdato',
					sortable: true,
				},
				{
					key: 'employeeCondition.employmentDate',
					label: 'Ansættelsedato',
					sortable: true,
				},

				{
					key: 'isActive',
					label: 'Synlig på virksomheden',
					sortable: true,
				},
			],
			fields: [
				{
					key: 'index',
					label: '',
				},
				{
					key: 'companyInfo.name',
					label: i18n.t('companyList.table.name'),
					sortable: true,
				},
				{
					key: 'companyInfo.cvr',
					label: i18n.t('companyList.table.cvr'),
					sortable: true,
				},
				{
					key: 'employees',
					label: i18n.t('companyList.table.employees'),
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$emit('updateLoader')
		await this.$store.dispatch('resignedCompanyVuex/getResignedEmployeePolicies') // Load data fra api+
	},
	methods: {
		currentPageChanged(page) {
			this.currentPage = page
		},
		perPageChanged(perPage) {
			this.perPage = perPage
		},
		getUser(policy) {
			var employeeId = policy.employeeId
			var user = this.users.find((user) => user.id === employeeId)
			return user?.fullName
		},
		getCpr(policy) {
			var employeeId = policy.employeeId
			var user = this.users.find((user) => user.id === employeeId)
			return user?.cpr
		},
		getFormattedDate(date) {
			if (date === null) {
				return ''
			}
			var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
			return new Date(date).toLocaleDateString('da-DK', options)
		},
		myCompare(a, b, key) {
			if (key === 'employees') {
				return a[key].length - b[key].length
			}
			return a[key] > b[key] ? 1 : -1
		},
	},
	computed: {
		companies() {
			var companies = this.$store.getters['company/companies']
			companies = companies.sort((a, b) => (a.companyInfo.name > b.companyInfo.name ? 1 : -1))

			// Filter companies based on existing employee policies
			var employeePolicies = this.employeePolicies.map((policy) => {
				policy.userName = this.getUser(policy)
				policy.cpr = this.getCpr(policy)
				return policy
			})
			// Only get employeePolicy with isActive = false OR resignedDate is not null
			employeePolicies = employeePolicies.sort((a, b) => (b.employeeCondition.resignationDate > a.employeeCondition.resignationDate ? 1 : -1))

			// Filter companies based on employeePolicies and map the employee policy into the company
			companies = companies.filter((company) => {
				const relevantPolicies = employeePolicies.filter((employeePolicy) => employeePolicy.companyId === company.id)
				if (relevantPolicies.length > 0) {
					company.employeePolicies = relevantPolicies
					return true
				}
				return false
			})

			return companies.filter((company) => {
				if (this.globalfilter === '') {
					return true
				}
				const filter = this.globalfilter.toLowerCase()
				return (
					company.companyInfo.name.toLowerCase().includes(filter) ||
					company.companyInfo.cvr.toString().toLowerCase().includes(filter) ||
					company.employeePolicies.some((policy) => policy.userName.toLowerCase().includes(filter)) ||
					company.employeePolicies.some((policy) => policy.cpr.toLowerCase().includes(filter))
				)
			})
		},
		users() {
			return this.$store.getters['user/users']
		},
		employeePolicies() {
			return this.$store.getters['resignedCompanyVuex/employeePolicies']
		},
		isBusy() {
			return !this.$store.getters['resignedCompanyVuex/isReady']
		},
		isAdvisorOrAdmin() {
			return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
		},
	},
}
</script>

<style scoped>
.container {
	max-width: 1200px; /* Adjust based on preference */
	margin: auto;
	padding: 20px;
}

.row {
	margin-bottom: 20px;
}

/* Typography */
body,
html {
	font-family: 'Segoe UI', Arial, sans-serif;
}

b,
strong {
	font-weight: 600;
}

/* Buttons and Inputs */
.b-button,
.input {
	border-radius: 5px;
	border: 1px solid #ccc;
}

.b-button {
	background-color: #0056b3;
	color: white;
	padding: 10px 15px;
}

.b-button:hover {
	background-color: #004494;
}

.input {
	padding: 10px;
	width: 100%;
}

/* Color Scheme */
:root {
	--primary-color: #0056b3;
	--secondary-color: #e9ecef;
	--text-color: #333;
}

/* Table Styling */
.BaseTable {
	width: 100%;
	border-collapse: collapse;
}

.BaseTable th,
.BaseTable td {
	text-align: left;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}

.BaseTable th {
	background-color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
	.col-auto,
	.col-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
</style>
